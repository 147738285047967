import { Observable, Subject } from "rxjs";
import { CompanyMetricsSchema } from "../schemas/company-metrics-schema";
import { Serializable } from "src/app/shared/models/Serializable";
import { progressProvider } from "../../shared/schemas/progressProvider";


export class CompanyMetrics extends Serializable implements progressProvider {
    
    private dataFilledSubject = new Subject<number>();
    public dataFilled$ = this.dataFilledSubject.asObservable();
    // private progressCheckService!: ProgressCheckService;

    constructor(
        public currentfulltimeemployees?: number | undefined,
        // TODO: Make these use an enum instead of a string
        public percentagenodegree?: string | undefined,
        public percentagesecondarydegree?: string | undefined,
        public percentageuniversitydegree?: string | undefined,
        public additionalemployeeslastyear?: number | undefined,
        public percentageemployeesunder30?: string | undefined,
        public percentagetraining?: string | undefined,
        public trainregularly?: string | boolean | undefined,
        public percentagewomen?: string | undefined,
        public equalpay?: string | boolean | undefined,
        public equalopportunities?: string | boolean | undefined,
        public maternityleave?: string | boolean | undefined,
        public flexibleworkinghours?: string | boolean | undefined,
        public childcaresupport?: string | boolean | undefined,
        public percentagefemaleleaders?: string | undefined,
        public energyefficientlighting?: string | boolean | undefined,
        public energyefficientappliances?: string | boolean | undefined,
        public energyaudits?: string | boolean | undefined,
        public solarpanels?: string | boolean | undefined,
        public greenenergy?: string | boolean | undefined,
        public recycling?: string | boolean | undefined,
        public reducepaperuse?: string | boolean | undefined,
        public reducewaterusage?: string | boolean | undefined,
        public minimalpackaging?: string | boolean | undefined,
        public percentagerenewable?: string | undefined,
        public mission?: string | undefined,
        public positiveimpact?: string | undefined,
        public mainchallenges?: string | undefined,
        public futuregoals?: string | undefined,
        public year?: number | undefined,
        
    ) {
        super();
    }


    public getProgress(): number {
      const ownProperties = Object.getOwnPropertyNames(this);
      let totalProperties = 0;
      let filledProperties = 0;
  
      for (const key of ownProperties) {
        const value = (this as any)[key];  
  
        if (typeof(value) !== 'function' && typeof(value) !== 'object' && !Array.isArray(value)) {
          totalProperties++;
          if (value !== undefined && value !== null) {
            filledProperties++;
          }
        }
      }
  
      const progress = totalProperties === 0 ? 0 : Math.floor((filledProperties / totalProperties) * 100)
      this.dataFilledSubject.next(progress);
      // console.log('CompanyMetricsData', this); 
      // console.log('totalProperties', totalProperties); 
      // console.log('filledProperties', filledProperties); 
      // console.log('CompanyMetricsData progress', progress); 
      return progress;
    }

    override deserialize(endpoint: string, metricsData: CompanyMetricsSchema): CompanyMetrics {

        // console.log('metricsData', metricsData);

        this.additionalemployeeslastyear = metricsData.additionalemployeeslastyear === null ? undefined : metricsData.additionalemployeeslastyear;
        this.childcaresupport = metricsData.childcaresupport === null ? undefined : (metricsData.childcaresupport === true || metricsData.childcaresupport === 'true') ? 'true' : 'false';
        this.currentfulltimeemployees = metricsData.currentfulltimeemployees === null ? undefined : metricsData.currentfulltimeemployees;
        this.energyaudits = metricsData.energyaudits === null ? undefined : (metricsData.energyaudits === true || metricsData.energyaudits === 'true') ? 'true' : 'false';
        this.energyefficientappliances = metricsData.energyefficientappliances === null ? undefined : (metricsData.energyefficientappliances === true || metricsData.energyefficientappliances === 'true') ? 'true' : 'false';
        this.energyefficientlighting = metricsData.energyefficientlighting === null ? undefined : (metricsData.energyefficientlighting === true || metricsData.energyefficientlighting === 'true') ? 'true' : 'false';
        this.equalopportunities = metricsData.equalopportunities === null ? undefined : (metricsData.equalopportunities === true || metricsData.equalopportunities === 'true') ? 'true' : 'false';
        this.equalpay = metricsData.equalpay === null ? undefined : (metricsData.equalpay === true || metricsData.equalpay === 'true') ? 'true' : 'false';
        this.flexibleworkinghours = metricsData.flexibleworkinghours === null ? undefined : (metricsData.flexibleworkinghours === true || metricsData.flexibleworkinghours === 'true') ? 'true' : 'false';
        this.futuregoals = metricsData.futuregoals === null ? undefined : metricsData.futuregoals;
        this.greenenergy = metricsData.greenenergy === null ? undefined : (metricsData.greenenergy === true || metricsData.greenenergy === 'true') ? 'true' : 'false';
        this.mainchallenges = metricsData.mainchallenges === null ? undefined : metricsData.mainchallenges;
        this.maternityleave = metricsData.maternityleave === null ? undefined : (metricsData.maternityleave === true || metricsData.maternityleave === 'true') ? 'true' : 'false';
        this.minimalpackaging = metricsData.minimalpackaging === null ? undefined : (metricsData.minimalpackaging === true || metricsData.minimalpackaging === 'true') ? 'true' : 'false';
        this.mission = metricsData.mission === null ? undefined : metricsData.mission;
        this.percentageemployeesunder30 = metricsData.percentageemployeesunder30 === null ? undefined : metricsData.percentageemployeesunder30;
        this.percentagefemaleleaders = metricsData.percentagefemaleleaders === null ? undefined : metricsData.percentagefemaleleaders;
        this.percentagenodegree = metricsData.percentagenodegree === null ? undefined : metricsData.percentagenodegree;
        this.percentagerenewable = metricsData.percentagerenewable === null ? undefined : metricsData.percentagerenewable;
        this.percentagesecondarydegree = metricsData.percentagesecondarydegree === null ? undefined : metricsData.percentagesecondarydegree;
        this.percentagetraining = metricsData.percentagetraining === null ? undefined : metricsData.percentagetraining;
        this.percentageuniversitydegree = metricsData.percentageuniversitydegree === null ? undefined : metricsData.percentageuniversitydegree;
        this.percentagewomen = metricsData.percentagewomen === null ? undefined : metricsData.percentagewomen;
        this.positiveimpact = metricsData.positiveimpact === null ? undefined : metricsData.positiveimpact;
        this.recycling = metricsData.recycling === null ? undefined : (metricsData.recycling === true || metricsData.recycling === 'true') ? 'true' : 'false';
        this.reducepaperuse = metricsData.reducepaperuse === null ? undefined : (metricsData.reducepaperuse === true || metricsData.reducepaperuse === 'true') ? 'true' : 'false';
        this.reducewaterusage = metricsData.reducewaterusage === null ? undefined : (metricsData.reducewaterusage === true || metricsData.reducewaterusage === 'true') ? 'true' : 'false';
        this.solarpanels = metricsData.solarpanels === null ? undefined : (metricsData.solarpanels === true || metricsData.solarpanels === 'true') ? 'true' : 'false';
        this.trainregularly = metricsData.trainregularly === null ? undefined : (metricsData.trainregularly === true || metricsData.trainregularly === 'true') ? 'true' : 'false';
        this.year = metricsData.year === null ? undefined : metricsData.year;

        // console.log('CompanyMetrics in deserialize', this);
        this.getProgress();

        return this;
    }

    override serialize(endpoint: string): any {

        return {
            "additionalemployeeslastyear": this.additionalemployeeslastyear,
            "childcaresupport": this.childcaresupport === 'true' ? true : false,
            "currentfulltimeemployees": this.currentfulltimeemployees || null,
            "energyaudits": this.energyaudits === 'true' ? true : false,
            "energyefficientappliances": this.energyefficientappliances === 'true' ? true : false,
            "energyefficientlighting": this.energyefficientlighting === 'true' ? true : false,
            "equalopportunities": this.equalopportunities === 'true' ? true : false,
            "equalpay": this.equalpay === 'true' ? true : false,
            "flexibleworkinghours": this.flexibleworkinghours === 'true' ? true : false,
            "futuregoals": this.futuregoals || null,
            "greenenergy": this.greenenergy === 'true' ? true : false,
            "mainchallenges": this.mainchallenges || null,
            "maternityleave": this.maternityleave === 'true' ? true : false,
            "minimalpackaging": this.minimalpackaging === 'true' ? true : false,
            "mission": this.mission || null,
            "percentageemployeesunder30": this.percentageemployeesunder30 || null,
            "percentagefemaleleaders": this.percentagefemaleleaders || null,
            "percentagenodegree": this.percentagenodegree || null,
            "percentagerenewable": this.percentagerenewable || null,
            "percentagesecondarydegree": this.percentagesecondarydegree || null,
            "percentagetraining": this.percentagetraining || null,
            "percentageuniversitydegree": this.percentageuniversitydegree || null,
            "percentagewomen": this.percentagewomen || null,
            "positiveimpact": this.positiveimpact || null,
            "recycling": this.recycling === 'true' ? true : false,
            "reducepaperuse": this.reducepaperuse === 'true' ? true : false,
            "reducewaterusage": this.reducewaterusage === 'true' ? true : false,
            "solarpanels": this.solarpanels === 'true' ? true : false,
            "trainregularly": this.trainregularly === 'true' ? true : false,
            "year": this.year,
        }
    }
}