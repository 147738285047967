import { Serializable } from "src/app/shared/models/Serializable";
import { DataTemplate } from "src/app/shared/schemas/request-template";
import { BalanceSheetData } from "./BalanceSheetData";
import { CapitalExpenditureData } from "./CapitalExpenditureData";
import { CompanyMetrics } from "./CompanyMetrics";
import { IncomeStatementData } from "./IncomeStatementData";
import { CompanyMetricsSchema } from "../schemas/company-metrics-schema";
import { BalanceSheetSchema } from "../schemas/balance-sheet-schema";
import { IncomeStatementSchema } from "../schemas/income-statement-schema";
import { CapitalExpenditureSchema } from "../schemas/capital-expenditure-schema";


export class CompanySnapshot extends Serializable {
    public year: number;
    // public financialDataFile: FinancialDataFile = new FinancialDataFile();
    public companyMetrics: CompanyMetrics = new CompanyMetrics();
    public balanceSheet: BalanceSheetData = new BalanceSheetData();
    public incomeStatement: IncomeStatementData = new IncomeStatementData();
    public capitalExpenditure: CapitalExpenditureData = new CapitalExpenditureData();
    

    constructor(year: number) {
        super();
        this.year = year;
    }

    override serialize(endpointUri: string): any {
        // let fin_data: Record<string, any>;
        let metric_data: Record<string, any>;
        let balancesheet_data: Record<string, any>;
        let incomestatement_data: Record<string, any>;
        let capitalexpenditure_data: Record<string, any>;

        switch (true) {
            // case endpointUri.includes('fundingoptions'):
            //     fin_data = this.financialDataFile ? this.financialDataFile.serialize() : {};
            //     fin_data["year"] = this.year;
            //     return fin_data
            case endpointUri.includes('companymetrics'):
                metric_data = this.companyMetrics ? this.companyMetrics.serialize("") : {}
                metric_data["year"] = this.year;
                // console.log('companyMetrics', metric_data);
                return metric_data
            case endpointUri.includes('balancesheet'):
                balancesheet_data = this.balanceSheet ? this.balanceSheet.serialize("") : {}
                balancesheet_data["year"] = this.year;
                // console.log('balancesheet', balancesheet_data);
                return balancesheet_data
            case endpointUri.includes('incomestatement'):
                incomestatement_data = this.incomeStatement ? this.incomeStatement.serialize("") : {}
                incomestatement_data["year"] = this.year;
                // console.log('incomestatement', incomestatement_data);
                return incomestatement_data
            case endpointUri.includes('capitalexpenditure'):
                capitalexpenditure_data = this.capitalExpenditure ? this.capitalExpenditure.serialize("") : {}
                capitalexpenditure_data["year"] = this.year;
                // console.log('capitalexpenditure', capitalexpenditure_data);
                return capitalexpenditure_data
            default:
                return {
                    "status": 404,
                    "message": "The endpoint you are trying to reach does not exist"
                }
        }
    }

    override deserialize(endpointUri: string, companyYearData: DataTemplate): CompanySnapshot {
        // let finData: FinancialsTemplate;
        let metricData: CompanyMetricsSchema;
        let balancesheetData: BalanceSheetSchema;
        let incomestatementData: IncomeStatementSchema;
        let capitalExpenditureData: CapitalExpenditureSchema;
        switch (true) {
            // case endpointUri.includes('fundingoptions'):
            //     finData = companyYearData as FinancialsTemplate;
            //     this.financialDataFile.deserialize(finData);
            //     break
            case endpointUri.includes('companymetrics'):
                metricData = companyYearData as CompanyMetricsSchema;
                this.companyMetrics.deserialize(endpointUri, metricData);
                break
            case endpointUri.includes('balancesheet'):
                balancesheetData = companyYearData as BalanceSheetSchema;
                this.balanceSheet.deserialize(endpointUri, balancesheetData);
                break
            case endpointUri.includes('incomestatement'):
                incomestatementData = companyYearData as IncomeStatementSchema;
                this.incomeStatement.deserialize(endpointUri, incomestatementData);
                break
            case endpointUri.includes('capitalexpenditure'):
                capitalExpenditureData = companyYearData as CapitalExpenditureSchema;
                this.capitalExpenditure.deserialize(endpointUri, capitalExpenditureData);
                break
            default:
                throw new Error("This CompanySnapshot path does not exist");
        }

        return this;
    }


}