import { Observable, Subject } from "rxjs";
import { Serializable } from "src/app/shared/models/Serializable";
import { progressProvider } from "../../shared/schemas/progressProvider";
import { CompanySnapshot } from "./CompanySnapshot";
import { CompanyProfileSchema } from "../schemas/company-profile-schema";

export class Company extends Serializable implements progressProvider {

  public companySnapshots: CompanySnapshot[] = [
    new CompanySnapshot(2022),
    new CompanySnapshot(2023),
  ];

  private dataFilledSubject = new Subject<number>();
  public dataFilled$ = this.dataFilledSubject.asObservable();
  // private progressCheckService!: ProgressCheckService;

  constructor(
    public businessName?: string | null,
    public numberOfEmployees?: number | null,
    public phoneNumber?: string | null,
    public managingDirector?: string | null,
    public emailAddress?: string | null,
    public majorityShareholder?: string | null,
    public website?: string | null,
    public industry?: string | null,
    public foundingYear?: number | null,
    public registrationId?: number | null,
    public description?: string | null,
    public address?: string | null,
    public country?: string | null
  ) {
    super();
  }
  

  public getProgress(): number {
    const ownProperties = Object.getOwnPropertyNames(this);
    let totalProperties = 0;
    let filledProperties = 0;
    const filledPropertiesArray = [];
    const totalPropertiesArray = [];

    for (const key of ownProperties) {
      const value = (this as any)[key];  
      if (typeof(value) !== 'function' && typeof(value) !== 'object' && !Array.isArray(value)) {
        totalProperties++;
        totalPropertiesArray.push({key: key, value: value});
        if (value !== undefined && value !== null) {
          filledPropertiesArray.push({key: key, value: value});
          filledProperties++;
        }
      }
    }

    const progress = totalProperties === 0 ? 0 : Math.floor((filledProperties / totalProperties) * 100)
    this.dataFilledSubject.next(progress);
    // console.log('CompanyData', this); 
    // console.log('totalPropertiesArray', totalPropertiesArray); 
    // console.log('filledPropertiesArray', filledPropertiesArray); 
    // console.log('CompanyData progress', progress);
    return progress;
  }

  override serialize(endpointUri: string): any {
    this.getProgress();
    console.log('CompanyData in serialize', this);
    return {
      "name": this.businessName,
      "industry": this.industry,
      "phonenumber": this.phoneNumber,
      "managingdirector": this.managingDirector,
      "emailaddress": this.emailAddress,
      "majorityshareholder": this.majorityShareholder,
      "website": this.website,
      "description": this.description,
      "foundingyear": this.foundingYear,
      "address": this.address,
      "country": this.country,
      "numberofemployees": this.numberOfEmployees,
      "registrationid": this.registrationId
    }
  }

  override deserialize(endpointUri: string, companyDetails: CompanyProfileSchema): Company {
    const businessName1 = companyDetails.businessname === null ? undefined : companyDetails.businessname;
    const businessName2 = companyDetails.name === null ? undefined : companyDetails.name;
    this.businessName = businessName1 ?? businessName2;
    this.address = companyDetails.address === null ? undefined : companyDetails.address;
    this.country = companyDetails.country === null ? undefined : companyDetails.country;
    this.description = companyDetails.description === null ? undefined : companyDetails.description;
    this.emailAddress = companyDetails.emailaddress === null ? undefined : companyDetails.emailaddress;
    this.foundingYear = companyDetails.foundingyear === null ? undefined : companyDetails.foundingyear;
    this.industry = companyDetails.industry === null ? undefined : companyDetails.industry;
    this.majorityShareholder = companyDetails.majorityshareholder === null ? undefined : companyDetails.majorityshareholder;
    this.managingDirector = companyDetails.managingdirector === null ? undefined : companyDetails.managingdirector;
    this.numberOfEmployees = companyDetails.numberofemployees === null ? undefined : companyDetails.numberofemployees;
    this.phoneNumber = companyDetails.phonenumber === null ? undefined : companyDetails.phonenumber;
    this.registrationId = companyDetails.registrationid === null ? undefined : companyDetails.registrationid;
    this.website = companyDetails.website === null ? undefined : companyDetails.website;
    this.getProgress();
    return this;
  }

  get getLatestSnapshot() {
    if (this.companySnapshots.length != 0) {
      return this.companySnapshots.reduce((prev, current) => {
        return (prev && prev.year > current.year) ? prev : current
      })
    } else {
      return undefined
    }
  }


  getSnapshotByYear(year: number): CompanySnapshot | undefined {
    return this.companySnapshots.find(snapshot => snapshot.year == year);
  }

}