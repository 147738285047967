import { Subject } from "rxjs";
import { Serializable } from "src/app/shared/models/Serializable";
import { progressProvider } from "../../shared/schemas/progressProvider";
import { BalanceSheetSchema } from "../schemas/balance-sheet-schema";


export class BalanceSheetData extends Serializable implements progressProvider {
  // Although we can calculate totalCurrentAssets, we will still send it to the backend as it is used frequently.
  // We will however not send totalAssets (same for Liabilities, Equity)
  private dataFilledSubject = new Subject<number>();
  public dataFilled$ = this.dataFilledSubject.asObservable();

  // private progressCheckService!: ProgressCheckService;

  constructor(
    public cash?: number | undefined,
    public inventory?: number | undefined,
    public accountsReceivable?: number | undefined,
    public prepaidExpenses?: number | undefined,
    public otherCurrentAssets?: number | undefined,

    public property?: number | undefined,
    public otherFixedAssets?: number | undefined,

    public accountsPayable?: number | undefined,
    public otherCurrentLiabilities?: number | undefined,

    public longTermDebt?: number | undefined,
    public otherLongTermLiabilities?: number | undefined,

    public ownerEquity?: number | undefined,
    public otherEquity?: number | undefined,
    public retainedEarnings?: number | undefined,
    public year?: number | undefined,
    public currency?: string | undefined

  ) {
    super();
  }

  public getProgress(): number {
    if (this.dataFilledSubject === null) {
      return 0;
    }

    const ownProperties = Object.getOwnPropertyNames(this);
    // console.log('BalanceSheetData', this); 
    let totalProperties = 0;
    let filledProperties = 0;
    const totalPropertiesArray = [];
    const filledPropertiesArray = [];

    for (const key of ownProperties) {
      const value = (this as any)[key];
      if (typeof (value) !== 'function' && typeof (value) !== 'object' && !Array.isArray(value)) {
        totalPropertiesArray.push({ key: key, value: value });
        totalProperties++;
        if (value !== undefined && value !== null) {
          filledPropertiesArray.push({ key: key, value: value });
          filledProperties++;
        }
      }
    }


    const progress = totalProperties === 0 ? 0 : Math.floor((filledProperties / totalProperties) * 100)
    this.dataFilledSubject.next(progress || 0);
    // console.log('BalanceSheetData', this); 
    // console.log('totalProperties', totalProperties); 
    // console.log('filledProperties', filledProperties); 
    // console.log('totalPropertiesArray', totalPropertiesArray); 
    // console.log('filledPropertiesArray', filledPropertiesArray); 
    // console.log('BalanceSheetData progress', progress);
    return progress || 0;
  }

  override serialize(endpoint: string): any {
    // console.log('BalanceSheetData in serialize', this)
    this.getProgress();
    return {
      "cash": this.cash || null,
      "inventory": this.inventory || null,
      "accountsreceivable": this.accountsReceivable || null,
      "prepaidexpenses": this.prepaidExpenses || null,
      "othercurrentassets": this.otherCurrentAssets || null,

      "property": this.property || null,
      "otherfixedassets": this.otherFixedAssets || null,

      "accountspayable": this.accountsPayable || null,
      "othercurrentliabilities": this.otherCurrentLiabilities || null,

      "longtermdebt": this.longTermDebt || null,
      "otherlongtermliabilities": this.otherLongTermLiabilities || null,

      "ownerequity": this.ownerEquity || null,
      "otherequity": this.otherEquity || null,
      "retainedearnings": this.retainedEarnings || null,
      "year": this.year,
      "currency": this.currency,
    };
  }

  override deserialize(endpoint: string, manualDetails: BalanceSheetSchema) {
    // console.log('BalanceSheetData in deserialize', manualDetails);
    this.cash = manualDetails.cash === null ? undefined : manualDetails.cash;
    this.inventory = manualDetails.inventory === null ? undefined : manualDetails.inventory;
    this.accountsReceivable = manualDetails.accountsreceivable === null ? undefined : manualDetails.accountsreceivable;
    this.prepaidExpenses = manualDetails.prepaidexpenses === null ? undefined : manualDetails.prepaidexpenses;
    this.otherCurrentAssets = manualDetails.othercurrentassets === null ? undefined : manualDetails.othercurrentassets;

    this.property = manualDetails.property === null ? undefined : manualDetails.property;
    this.otherFixedAssets = manualDetails.otherfixedassets === null ? undefined : manualDetails.otherfixedassets;

    this.accountsPayable = manualDetails.accountspayable === null ? undefined : manualDetails.accountspayable;
    this.otherCurrentLiabilities = manualDetails.othercurrentliabilities === null ? undefined : manualDetails.othercurrentliabilities;

    this.longTermDebt = manualDetails.longtermdebt === null ? undefined : manualDetails.longtermdebt;
    this.otherLongTermLiabilities = manualDetails.otherlongtermliabilities === null ? undefined : manualDetails.otherlongtermliabilities;

    this.ownerEquity = manualDetails.ownerequity === null ? undefined : manualDetails.ownerequity;
    this.otherEquity = manualDetails.otherequity === null ? undefined : manualDetails.otherequity;
    this.retainedEarnings = manualDetails.retainedearnings === null ? undefined : manualDetails.retainedearnings;
    this.year = manualDetails.year === null ? undefined : manualDetails.year;
    this.currency = manualDetails.currency === null ? undefined : manualDetails.currency;

    // console.log('BalanceSheetData in deserialize', this)
    this.getProgress();
    return this;

  }

  
}