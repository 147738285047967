import { Subject } from "rxjs";
import { CapitalExpenditureSchema } from "../schemas/capital-expenditure-schema";
import { Serializable } from "src/app/shared/models/Serializable";
import { progressProvider } from "../../shared/schemas/progressProvider";

export class CapitalExpenditureData extends Serializable implements progressProvider {
  // Although we can calculate totalCurrentAssets, we will still send it to the backend as it is used frequently.
  // We will however not send totalAssets (same for Liabilities, Equity)

  private dataFilledSubject = new Subject<number>();
  public dataFilled$ = this.dataFilledSubject.asObservable();

  // private progressCheckService!: ProgressCheckService;

  constructor(
    public productionEquipment?: number | undefined,
    public land?: number | undefined,
    public building?: number | undefined,
    public vehicles?: number | undefined,
    public labEquipment?: number | undefined,
    public computerHardware?: number | undefined,
    public otherEquipment?: number | undefined,
    public year?: number | undefined,
    public currency?: string | undefined

  ) {
    super();
  }


  public getProgress(): number {
    if (this.dataFilledSubject === null) {
      return 0;
    }

    const ownProperties = Object.getOwnPropertyNames(this);
    let totalProperties = 0;
    let filledProperties = 0;

    for (const key of ownProperties) {
      const value = (this as any)[key];
      if (typeof (value) !== 'function' && typeof (value) !== 'object' && !Array.isArray(value)) {
        totalProperties++;
        if (value !== undefined && value !== null) {
          filledProperties++;
        }
      }
    }

    const progress = totalProperties === 0 ? 0 : Math.floor((filledProperties / totalProperties) * 100)
    this.dataFilledSubject.next(progress);
    // this.progressCheckService?.sendProgressUpdate();
    // console.log('CapitalExpenditureData', this); 
    // console.log('totalProperties', totalProperties); 
    // console.log('filledProperties', filledProperties); 
    // console.log('CapitalExpenditureData progress', progress);
    return progress;
  }

  override serialize(endpoint: string): any {
    // console.log('CapitalExpenditureData in serialize', this)
    this.getProgress();
    return {
      "productionequipment": this.productionEquipment || null,
      "land": this.land || null,
      "building": this.building || null,
      "vehicles": this.vehicles || null,
      "labequipment": this.labEquipment || null,
      "ithardware": this.computerHardware || null,
      "otherequipment": this.otherEquipment || null,
      "year": this.year,
      "currency": this.currency,
    };
  }

  override deserialize(endpoint: string, manualDetails: CapitalExpenditureSchema) {
    this.productionEquipment = manualDetails.productionequipment === null ? undefined : manualDetails.productionequipment;
    this.land = manualDetails.land === null ? undefined : manualDetails.land;
    this.building = manualDetails.building === null ? undefined : manualDetails.building;
    this.vehicles = manualDetails.vehicles === null ? undefined : manualDetails.vehicles;
    this.labEquipment = manualDetails.labequipment === null ? undefined : manualDetails.labequipment;
    this.computerHardware = (manualDetails.ithardware || manualDetails.computerhardware) === null ? undefined : manualDetails.ithardware || manualDetails.computerhardware;
    this.otherEquipment = manualDetails.otherequipment === null ? undefined : manualDetails.otherequipment;
    this.year = manualDetails.year === null ? undefined : manualDetails.year;
    this.currency = manualDetails.currency === null ? undefined : manualDetails.currency;

    // console.log('CapitalExpenditureData in deserialize', this)
    this.getProgress();

    return this;

  }

}