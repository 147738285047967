import { computed, Injectable, signal } from '@angular/core';
import { Company } from '../models/Company';
import { RequestService } from 'src/app/shared/data-access/request-service';
import { environment } from 'src/environments/environment';
import { CompanySnapshot } from '../models/CompanySnapshot';
import { HttpClient } from '@angular/common/http';
import { forkJoin, map } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class CompanyService {

  public companyData = signal<Company>({} as Company);
  public companyDataAvailable = signal<boolean>(false);
  public allSnapshotsAvailable = signal<boolean>(false);

  public company: Company = new Company();
  private requestService = new RequestService(this.http);

  private companyEndpointUri = environment.BASE_URL + "company/";
  private companyMetricsEndpointUri = environment.BASE_URL + "companymetrics/";
  private balanceSheetEndpointUri = environment.BASE_URL + "balancesheet/";
  private incomeStatementEndpointUri = environment.BASE_URL + "incomestatement/";
  private capitalExpenditureEndpointUri = environment.BASE_URL + "capitalexpenditure/";

  constructor(
    private http: HttpClient
  ) { }

  initializeCompanySnapshots() {

    try {
      const companySnapshotData2022 = this.company.getSnapshotByYear(2022);
      const companySnapshotData2023 = this.company.getSnapshotByYear(2023);
    
      const requests = [];
    
      if (companySnapshotData2022) {
        requests.push(
          this.requestService.loadObject<CompanySnapshot>(this.balanceSheetEndpointUri + '2022', companySnapshotData2022),
          this.requestService.loadObject<CompanySnapshot>(this.incomeStatementEndpointUri + '2022', companySnapshotData2022),
          this.requestService.loadObject<CompanySnapshot>(this.capitalExpenditureEndpointUri + '2022', companySnapshotData2022)
        );
      }
    
      if (companySnapshotData2023) {
        requests.push(
          this.requestService.loadObject<CompanySnapshot>(this.balanceSheetEndpointUri + '2023', companySnapshotData2023),
          this.requestService.loadObject<CompanySnapshot>(this.incomeStatementEndpointUri + '2023', companySnapshotData2023),
          this.requestService.loadObject<CompanySnapshot>(this.capitalExpenditureEndpointUri + '2023', companySnapshotData2023),
          this.requestService.loadObject<CompanySnapshot>(this.companyMetricsEndpointUri + '2023', companySnapshotData2023)
        );
      }
    
      this.requestService.loadObject<Company>(this.companyEndpointUri, this.company).pipe(
        map((companyData) => {
          this.companyData.set(companyData);
          this.companyDataAvailable.set(true);
          return companyData;
        })
      ).subscribe();
    
      // Using forkJoin to wait for all requests to complete and assign the 'allSnapshotsAvailable' signal to 'true'
      forkJoin(requests).subscribe({
        next: (results) => {
          this.allSnapshotsAvailable.set(true);
        },
        error: (error) => {
          console.error('Error fetching data:', error);
        }
      });
    
    } catch (error) {
      console.error('Error fetching data:', error);
    }
    

  }

  getCompanySnapshotByYear(year: number) {
    return this.company.getSnapshotByYear(year);
  }

  postCompanyData() {
    return this.requestService.postRequest(
      this.companyEndpointUri,
      this.company
    );
  }

  postCompanyMetricsData(year: number) {
    const companySnapshotData = this.company.getSnapshotByYear(year);

    if (companySnapshotData) {
      return this.requestService.postRequest(this.companyMetricsEndpointUri + year, companySnapshotData);
    }
    return null;
  }

  postBalanceSheetData(year: number) {
    const companySnapshotData = this.company.getSnapshotByYear(year);
    if (companySnapshotData) {
      return this.requestService.postRequest(this.balanceSheetEndpointUri + year, companySnapshotData);
    }
    return null;
  }
  postIncomeStatementData(year: number) {
    const companySnapshotData = this.company.getSnapshotByYear(year)?.incomeStatement;
    if (companySnapshotData) {
      return this.requestService.postRequest(this.incomeStatementEndpointUri + year, companySnapshotData);
    }
    return null;
  }
  postCapitalExpenditureData(year: number) {
    const companySnapshotData = this.company.getSnapshotByYear(year)?.capitalExpenditure;
    if (companySnapshotData) {
      return this.requestService.postRequest(this.capitalExpenditureEndpointUri + year, companySnapshotData);
    }
    return null;
  }

  postAllForms(currency: string) {
    const bsSnapshot2022 = this.company.getSnapshotByYear(2022);
    if (bsSnapshot2022?.balanceSheet) {
      bsSnapshot2022.balanceSheet.currency = currency;
      this.postBalanceSheetData(2022);
    }
    const bsSnapshot2023 = this.company.getSnapshotByYear(2023);
    if (bsSnapshot2023?.balanceSheet) {
      bsSnapshot2023.balanceSheet.currency = currency;
      this.postBalanceSheetData(2023);
    }

    const isSnapshot2022 = this.company.getSnapshotByYear(2022);
    if (isSnapshot2022?.incomeStatement) {
      isSnapshot2022.incomeStatement.currency = currency;
      this.postIncomeStatementData(2022);
    }
    const isSnapshot2023 = this.company.getSnapshotByYear(2023);
    if (isSnapshot2023?.incomeStatement) {
      isSnapshot2023.incomeStatement.currency = currency;
      this.postIncomeStatementData(2023);
    }

    const ceSnapshot2022 = this.company.getSnapshotByYear(2022);
    if (ceSnapshot2022?.capitalExpenditure) {
      ceSnapshot2022.capitalExpenditure.currency = currency;
      this.postCapitalExpenditureData(2022);
    }
    const ceSnapshot2023 = this.company.getSnapshotByYear(2023);
    if (ceSnapshot2023?.capitalExpenditure) {
      ceSnapshot2023.capitalExpenditure.currency = currency;
      this.postCapitalExpenditureData(2023);
    }

  }
}
