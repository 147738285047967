import { Subject } from "rxjs";
import { Serializable } from "src/app/shared/models/Serializable";
import { IncomeStatementSchema } from "../schemas/income-statement-schema";
import { progressProvider } from "../../shared/schemas/progressProvider";


export class IncomeStatementData extends Serializable implements progressProvider {
  // Although we can calculate totalCurrentAssets, we will still send it to the backend as it is used frequently.
  // We will however not send totalAssets (same for Liabilities, Equity)
  private dataFilledSubject = new Subject<number>();
  public dataFilled$ = this.dataFilledSubject.asObservable();

  // private progressCheckService!: ProgressCheckService;

  constructor(
    public sales?: number | undefined,
    public otherincome?: number | undefined,
    // public totalrevenue?: number | undefined,
    public costofmaterials?: number | undefined,
    public productionlaborcosts?: number | undefined,
    public otherproductioncosts?: number | undefined,
    // public totalcogs?: number | undefined,
    // public grossprofit?: number | undefined,
    public nonproductionsalaries?: number | undefined,
    public rent?: number | undefined,
    public utilities?: number | undefined,
    public marketing?: number | undefined,
    public depreciation?: number | undefined,
    public otheroperatingexpenses?: number | undefined,
    public interestexpense?: number | undefined,
    // public othernonoperatingexpenses?: number | undefined,
    // public netincomebeforetaxes?: number | undefined,
    public incometaxexpense?: number | undefined,
    // public netincome?: number | undefined,
    // public totaloperatingincome?: number | undefined,
    // public totaloperatingexpenses?: number | undefined,

    public year?: number | undefined,
    public currency?: string | undefined



  ) {
    super();
  }

  public getProgress(): number {
    if (this.dataFilledSubject === null) {
      return 0;
    }

    const ownProperties = Object.getOwnPropertyNames(this);
    let totalProperties = 0;
    let filledProperties = 0;

    for (const key of ownProperties) {
      const value = (this as any)[key];
      if (typeof (value) !== 'function' && typeof (value) !== 'object' && !Array.isArray(value)) {
        totalProperties++;
        if (value !== undefined && value !== null) {
          filledProperties++;
        }
      }
    }

    const progress = totalProperties === 0 ? 0 : Math.floor((filledProperties / totalProperties) * 100)
    this.dataFilledSubject.next(progress);
    // console.log('IncomeStatementData', this); 
    // console.log('totalProperties', totalProperties); 
    // console.log('filledProperties', filledProperties); 
    // console.log('IncomeStatementData Progress', progress); 
    return progress;

  }



  override serialize(endpoint: string): any {

    // console.log('IncomeStatementData in serialize', this)
    this.getProgress();

    return {
      "sales": this.sales || null,
      "otherincome": this.otherincome || null,
      // "totalrevenue": this.totalrevenue,
      "costofmaterials": this.costofmaterials || null,
      "productionlaborcosts": this.productionlaborcosts || null,
      "otherproductioncosts": this.otherproductioncosts || null,
      // "totalcogs": this.totalcogs,
      // "grossprofit": this.grossprofit,
      "nonproductionsalaries": this.nonproductionsalaries || null,
      "rent": this.rent || null,
      "utilities": this.utilities || null,
      "marketing": this.marketing || null,
      "depreciation": this.depreciation || null,
      "otheroperatingexpenses": this.otheroperatingexpenses || null,
      "interestexpense": this.interestexpense || null,
      // "othernonoperatingexpenses": this.othernonoperatingexpenses,
      // "netincomebeforetaxes": this.netincomebeforetaxes,
      "incometaxexpense": this.incometaxexpense || null,
      // "netincome": this.netincome,
      // "totaloperatingincome": this.totaloperatingincome,
      // "totaloperatingexpenses": this.totaloperatingexpenses,
      "year": this.year,
      "currency": this.currency,
    };
  }

  override deserialize(endpoint: string, manualDetails: IncomeStatementSchema) {
    this.sales = manualDetails.sales === null ? undefined : manualDetails.sales;
    this.otherincome = manualDetails.otherincome === null ? undefined : manualDetails.otherincome;
    this.costofmaterials = manualDetails.costofmaterials === null ? undefined : manualDetails.costofmaterials;
    this.productionlaborcosts = manualDetails.productionlaborcosts === null ? undefined : manualDetails.productionlaborcosts;
    this.otherproductioncosts = manualDetails.otherproductioncosts === null ? undefined : manualDetails.otherproductioncosts;
    this.nonproductionsalaries = manualDetails.nonproductionsalaries === null ? undefined : manualDetails.nonproductionsalaries;
    this.rent = manualDetails.rent === null ? undefined : manualDetails.rent;
    this.utilities = manualDetails.utilities === null ? undefined : manualDetails.utilities;
    this.marketing = manualDetails.marketing === null ? undefined : manualDetails.marketing;
    this.depreciation = manualDetails.depreciation === null ? undefined : manualDetails.depreciation;
    this.otheroperatingexpenses = manualDetails.otheroperatingexpenses === null ? undefined : manualDetails.otheroperatingexpenses;
    this.interestexpense = manualDetails.interestexpense === null ? undefined : manualDetails.interestexpense;
    this.incometaxexpense = manualDetails.incometaxexpense === null ? undefined : manualDetails.incometaxexpense;
    this.year = manualDetails.year === null ? undefined : manualDetails.year;
    this.currency = manualDetails.currency === null ? undefined : manualDetails.currency;

    // console.log('IncomeStatementData in deserialize', this)
    this.getProgress();

    return this;

  }


}